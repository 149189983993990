import React, { useState } from "react";
import Text from "../atoms/Text";
import Layout from "../organisms/Layout";
import Wrapper from "../atoms/Wrapper";
import Container from "../atoms/Container";
import { PageSEO } from "../atoms/SEO";

type PROPS_STYLE = {
  location: any;
};

export default function PrivacyPolicy(props: PROPS_STYLE) {
  const [showWaitListForm, setShowWaitListForm] = useState(false); //to keep track of visibility of modal

  function displayWaitListForm() {
    //display the wait-list modal/form by setting showWaitListFrom to true
    // setShowWaitListForm(true);
    window.open("https://form.jotform.com/240982279984475", "_blank");
  }

  function hideDisplayWaitListForm() {
    //hide the wait-list modal/form by setting showWaitListFrom to false
    setShowWaitListForm(false);
  }

  return (
    <Layout
      hideDisplayWaitListForm={hideDisplayWaitListForm}
      showWaitListForm={showWaitListForm}
      displayWaitListForm={displayWaitListForm}
    >
      <PageSEO
        path={props.location.pathname}
        title={"Privacy Policy - SaaSPay"}
        description={
          "SaaSPay - Privacy Policy. Read more about the policy that helps us maintain the required levels of safety for our website users and the data generated as per RBI guidelines."
        }
      />

      <Wrapper>
        <Container className={"privacy-policy py-20 px-4 xl:px-0"}>
          <div className={"text-center my-[60px] "}>
            <Text text={"Privacy Policy"} textStyle={"h1"} />
          </div>
          <Text
            component={"h1"}
            text={"LAST UPDATED: 13-02-2023"}
            textStyle={"h4"}
          />
          <br />
          <br />
          <div className={"text-justify text-[#323B4B] open-400-16-28 body"}>
            SaaSpay is a company that provides an online credit platform for
            businesses to deploy cloud software services via a deploy now pay
            later option by providing financing options to the buyer to deploy
            the products and the services.
            <br />
            <br />
            This Privacy Policy describes the data and/or information we use and
            the manner in which the said data and/or information may be shared
            with others. This Privacy Policy (“<b>Policy</b>”) should be read
            along with the terms of use provided on SaaSpay’s website–
            (http://www.saaspay.com/). On using the platform and/or services of
            SaaSpay, you agree to be bound to the terms of use and privacy
            policy as updated and amended from time to time. All capitalized
            terms not defined under this policy shall have the same meaning
            assigned to it under the Terms of Use.
            <br />
            <br />
            <br />
            <h3>
              <u>1. Data collected:</u>
            </h3>
            <h4>
              <u>1.1 Personal Information:</u>
            </h4>
            Personal Information shall mean and include all information relating
            to the unique identity of the user such as name, age, gender,
            address, email id and contact details. For the efficient rendering
            of services, SaaSpay collects certain information such as name,
            email address, contact details, full name, cookies and usage.
            SaaSPay may also collect credit and lending information about you
            which includes details like income statements, balance sheets,
            cashflow statements, credit scores, etc.. The said details shall be
            collected for enabling KYC verification, identity check, user
            authentication process etc. Information provided by you shall at all
            times be accurate, and true and be updated from time to time.
            SaaSpay may also retain the data for the purpose of providing
            promotional offers, newsletters etc. However for usage of Personal
            Information containing bio-metric data, SaaSpay shall obtain
            requisite permissions and approvals from statutory authorities.
            <h4>
              <u>1.2 Sensitive Information:</u>
            </h4>
            Sensitive information shall mean financial information such as
            e-nach, bank account details, credit/debit card details, NEFT
            details, UPI ID and other payment instrument details. SaaSpay may
            collect such financial information as voluntarily provided by you
            for transacting purchases made by you via SaaSpay. SaaSpay shall
            retain the data collected and may share such information with the
            required third-parties such as commercial partners, affiliates,
            payment facilitators, credit information companies. Please read the
            relevant privacy notice of such third-parties before transacting.
            <h4>
              <u>1.3 Domain/IP Information:</u>
            </h4>
            During the course of usage of our website/application, we may have
            automatic access to your domain name and IP address. Such
            information collected shall not reveal any specific user identity
            details but may reveal information such as device and storage
            information, IP address, browser and device characteristics,
            operating system, language preferences, referring URLs, device name,
            country, location, information pertaining to manner and time of
            usage of SaaSpay’s Services, and other technical information. The
            information acquired shall be used primarily for security purposes
            and for administering and monitoring our internal operating system.
            <h4>
              <u>1.4 Temporary access:</u>
            </h4>
            For facilitating on-boarding KYC verification, SaaSpay shall require
            one-time access to features of your mobile phone such as camera,
            microphone, location,sms services etc.
            <h4>
              <u>1.5 Other Information:</u>
            </h4>
            <br />
            <h3>
              <u>2. Usage of data:</u>
            </h3>
            SaaSpay shall process the information for a variety of reasons
            depending on whether you are a buyer, seller, merchant, borrower,
            service provider or a business partner. The primary usage of
            information shall be for the purpose of creating login credentials.
            This is necessary to enable SaaSpay to scrutinize your application
            and provide you the requisite services and comply with the relevant
            laws of India.
            <br />
            <br />
            <ul>
              <li>
                To facilitate account creation and authentication and/or manage
                user account, SaaSpay shall require this information to enable
                you to sign into your account, maintain your account and keep it
                working.
              </li>
              <li>
                For efficient rendering of services to you and for transacting
                the services. For transactional purposes SaaSpay may require
                Personal and Sensitive Information that may allow third party
                vendors and payment gateways to process the transaction.
              </li>
              <li>
                To monitor the manner of usage of the platform and diagnose
                technical problems if any. SaaSpay shall require such Personal
                and Sensitive Information to enable it to assess potential
                technical discrepancies and diagnose them for rendering
                quality-driven services.
              </li>
              <li>
                For verification of your identity and eligibility to use the
                platform. SaaSpay shall require personal and sensitive
                information for the purpose of remembering basic user data to
                enable you to use its services.
              </li>
              <li>
                To offer any support or respond to any queries put forward by
                you. SaaSpay shall provide end-to-end user services by resolving
                any technical difficulties encountered by you in the course of
                usage of its services
              </li>
              <li>
                To send administrative information with respect to changes in
                privacy policy, changes in our terms and policies and such other
                relevant information. To enable users to be abreast of its
                revised policies and terms, SaaSpay shall require such personal
                and sensitive information which shall enable it to notify you
                promptly.
              </li>
              <li>
                For the purpose of troubleshooting and protection against any
                illegal and/or criminal activities.
              </li>
              <li>
                To analyze the potential risks with respect to KYC
                verifications, monitor and assess the fraud rates and analyze
                the possible methods of reduction of such fraud rates.
              </li>
              <li>
                To share such information with our related business entities,
                partners and affiliates for the provision of new products and
                services to you.
              </li>
              <li>
                For advertising and marketing of services that are in existence
                or may come into existence in the future. With the view to
                provide targeted customer advertising and marketing, SaaSpay may
                share your information to its partners/affiliates to provide
                other customized goods and services to you.
              </li>
              <li>
                To save or protect an individual's vital interest. SaaSpay may
                process your information when necessary to save or protect an
                individual’s vital interest, such as to prevent harm.
              </li>
              <li>
                To fulfill and manage your orders. SaaSpay may process your
                information to fulfill and manage your orders, payments,
                returns, and exchanges made through the Services.
              </li>
              <li>
                To manage and enhance our relationship with you, SaaSpay shall
                keep a track of the manner and usage of the its
                website/application to develop the products and services
                catering to your needs.
              </li>
              <li>
                To identify usage trend. SaaSpay may process information about
                how you use SaaSpay’s Services to better understand how they are
                being used and how they can be improved.
              </li>
            </ul>
            <br />
            <h3>
              <u>3. Storage of Data:</u>
            </h3>
            SaaSpay shall retain your information as long as it is necessary as
            set out in this policy for compliance with legal regulations such as
            taxation, dispute resolution, enforcement of legal contracts and
            agreements.
            <br />
            <br />
            For the purpose of analysis and research, SaaSpay may maintain your
            information for a short period of time. However for the purpose of
            improving the functionality, efficiency of services, SaaSpay shall
            retain the information for a longer period of time.
            <br />
            <br />
            SaaSpay shall ensure secure storage of data and/or information
            stored in servers leased from third-party hosting services located
            in Mumbai, India.
            <br />
            <br />
            <h3>
              <u>4. Disclosure of Information:</u>
            </h3>
            SaaSpay may share your information with third-party vendors, payment
            gateways, service providers, contractors, agents, regulated entities
            like financial institutions who render services to us. The said
            third parties have entered into requisite contracts with us that
            shall bound them with contractual and confidentiality obligations
            similar to us. The said third parties endeavour to protect your
            information, shared with them for the period till which they retain
            such information. The categories of Third Parties may include ad
            agencies, networks, cloud computing software, data analytics
            services, government entities, payment processors, retargeting
            platforms, social networks, Google Maps Platform API and any other
            Third Parties who are required for us to provide you the Services.
            We may also share your Personal Information with our affiliates,
            subject to similar binding obligations on such affiliates.
            Affiliates include our holding company and any subsidiaries, joint
            venture partners, or other companies that we control or that are
            under common control with us.
            <br />
            <br />
            However, SaaSpay shall disclose and/or share such information:
            <br />
            <br />
            <ul>
              <li>
                In the event the company undergoes any internal restricting, in
                the event of sale of the company, merger, acquisition,
                bankruptcy etc.
              </li>
              <li>
                For compliance with certain laws and/or regulations or if such
                disclosure is required by a government authority/ statutory
                authority and such other bodies authorized by laws to access
                such information.
              </li>
              <li>
                To enforce the terms of use and protect the rights of the
                company including but not limited to intellectual property
                rights of the company. However, SaaSpay will not be liable for
                disclosure of information caused due to force majeure events,
                power failures , delineation and corruption of services.
              </li>
            </ul>
            <br />
            <h3>
              <u>5. User Rights:</u>
            </h3>
            You may exercise your individual privacy rights to amend your
            Personal Information and withdraw permission to the processing of
            your Sensitive Information. In accordance with applicable laws and
            as per our policies, you can exercise the following rights, in a
            reasonable manner and with adequate notice:
            <br />
            <br />
            Review, Correct or Rectify your Personal Information: You shall at
            all times have the right to edit and/or rectify your information.
            <br />
            <br />
            Right to withdraw your consent: You may at your discretion opt out
            of the services of SaaSpay and/or the promotional materails offered
            by SaaSpay.
            <br />
            <br />
            Right to access: You may request record of your Personal Information
            (including Sensitive Information) being processed by us, upon the
            verification of your identity.
            <br />
            <br />
            <h3>
              <u>6. Updates on Privacy Policy:</u>
            </h3>
            We may update this privacy policy from time to time. The privacy
            policy was last revised as identified at the top of this privacy
            policy. If you continue to use the Application or avail our
            services, you agree to be bound by the most recent version of the
            privacy policy as available on the website.
            <br />
            <br />
            It is pertinent that the Personal Information we hold about you is
            accurate and current. Please keep us informed if your Personal
            Information changes during your relationship with us.
            <br />
            <br />
            <i>
              <u>Contact us</u>
            </i>
            : If you have any questions related to the privacy policy and/or our
            privacy practices please reach out to us at{" "}
            <a href={"mailto:support@saaspay.app"}>support@saaspay.app</a>
          </div>
        </Container>
      </Wrapper>
    </Layout>
  );
}
